// @ts-check

export default class AsyncSubmitForm extends HTMLElement {
	connectedCallback() {
		this.form = /** @type{HTMLFormElement} */ (
			this.querySelector(":scope form")
		);

		if (!this.form) return;

		this.handleSubmit = this.handleSubmit.bind(this);

		this.errorElement = /** @type{HTMLElement} */ (
			this.form.querySelector("[data-form-error]")
		);
		this.submitButton = /** @type{HTMLInputElement} */ (
			this.form.querySelector("button,input[type=submit]")
		);
		this.form.addEventListener("submit", this.handleSubmit);
	}

	disconnectedCallback() {
		this.form?.removeEventListener("submit", this.handleSubmit);
	}

	resetForm() {
		this.form?.reset();
		this.hideErrorMessage();
	}

	setFormBusy() {
		if (this.submitButton) {
			this.submitButton.ariaBusy = "true";
			this.submitButton.disabled = true;
		}
	}

	resetFormBusy() {
		if (this.submitButton) {
			this.submitButton.ariaBusy = "false";
			this.submitButton.disabled = false;
		}
	}

	hideErrorMessage() {
		if (this.errorElement) {
			this.errorElement.style.display = "none";
		}
	}

	showErrorMessage(message = "Something went wrong. Try again later.") {
		if (this.errorElement) {
			this.errorElement.innerText = message;
			this.errorElement.style.display = "block";
		}
	}

	async handleSubmit(event) {
		if (!this.form) return;

		event.preventDefault();
		this.setFormBusy();
		this.hideErrorMessage();

		const action = this.form?.action;
		const formData = new FormData(this.form);
		const formEntries = /** @type {string[][]} */ ([...formData.entries()]);
		const body = new URLSearchParams(formEntries).toString();
		const onSuccessAction = this.form.dataset.onSuccessAction;

		try {
			const res = await fetch(action, {
				method: "POST",
				body,
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
			});
			this.resetFormBusy();
			if (res.ok) {
				this.resetForm();
				if (onSuccessAction) {
					window.location.href = onSuccessAction;
				}
			} else {
				const json = await res.json();
				this.showErrorMessage(json?.message);
			}
		} catch {
			this.resetFormBusy();
			this.showErrorMessage();
		}
	}
}

customElements.define("async-form-submit", AsyncSubmitForm);
